<template>
	<div class="load-container" :class="{'load' : isLoad}">

		<table class="table-custom" v-if="houses.length > 0">
			<thead>
				<tr>
					<th>№</th>
					<th>Адрес</th>
					<th>Управляющий домом (ФИО)</th>
					<th>Всего заданий (кол-во)</th>
					<th class="nowrap">Сделано, %</th>
					<th>Сделано, есть проблема, %</th>
					<th class="nowrap">Не сделано, %</th>
					<th class="nowrap">Просрочено, %</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(house, index) in houses" :key="index">
					<td width="1px">{{ index + 1 }}</td>
					<td>
						{{house.house.charAt(0).toUpperCase() + house.house.slice(1).trim()}}
					</td>
					<td>
						<template v-if="house.emplLastName !== null && house.emplLastName.length !== 0">
							{{house.emplLastName.charAt(0).toUpperCase() + house.emplLastName.slice(1).trim()}}
						</template>
		  				<template v-if="house.emplFirstName !== null && house.emplFirstName.length !== 0">&nbsp;{{house.emplFirstName.trim()}}</template>
		  				<template v-if="house.emplMiddleName !== null && house.emplMiddleName.length !== 0">&nbsp;{{house.emplMiddleName.trim()}}</template>
					</td>
					<td>
						<span class="number"> {{ house.totalTasks }} </span>
					</td>
					<td>
						<span class="number number--blue"> {{ house.donePercent + '%'}} </span>
					</td>
					<td>
						<span class="number number--orange"> {{ house.issuePercent + '%'}} </span>
					</td>
					<td>
						<span class="number number--red"> {{ house.failedPercent + '%'}} </span>
					</td>
					<td>
						<span class="number number--dark-red"> {{ house.expiredPercent + '%'}} </span>
					</td>
					
				</tr>
			</tbody>
		</table>

		<div class="empty-data" v-else>
		  	Нет данных
		</div>
	</div>
</template>


<script>
  	export default {
	    
	    name: 'HousesInfo',
	    
	    props: ['organization_id', 'begin', 'end'],

        expose: ['loadData'],

	    data: () => ({
	    	isLoad: true,
	    	houses: [],
	    }),

	    created () {
	    	this.loadData();
	    },

	    methods: {

	    	buildfilter () {
		        const param = new URLSearchParams({});
		        param.set("company_id", this.organization_id);
		        if(this.begin !== null) {
		          param.set("begin", this.begin + 'T00:00:00.000000+00:00');
		        }
		        if(this.end !== null) {
		          param.set("end", this.end + 'T23:59:59.999999+00:00');
		        }
		        return param;
		    },

	    	async loadData () {
	    		this.isLoad = true;
	    		const param = this.buildfilter();
		        const {data} = await this.axios.get(this.$store.state.api_endpoint + '/dashboard/report-house-director?' + param);
		        this.houses = data.houses;
	    		this.isLoad = false;
	    	},

	    }
	}
</script>

<style scoped>
	.number {
		font-weight: 600;
	}
	.number--orange {
		color: var(--cozh-orange);
	}
	.number--blue {
		color: var(--cozh-blue);
	}
	.number--red {
		color: var(--cozh-red);
	}
	.number--dark-red {
		color: var(--cozh-dark-red);
	}
</style>
