<template>
	<div class="load-container" :class="{'load' : isLoad}">

		<table class="table-custom" v-if="employees.length > 0">
			<thead>
				<tr>
					<th>№</th>
					<th>ФИО сотрудника</th>
					<th>Должность</th>
					<th>Всего заданий</th>
					<th class="nowrap">Выполненно, %</th>
					<th class="nowrap">Просрочено, %</th>
					<th>Управляющий</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(employee, index) in employees" :key="index">
					<td width="1px">{{ index + 1 }}</td>
					<td class="nowrap">
						<template v-if="employee.emplLastName !== null && employee.emplLastName.length !== 0">{{employee.emplLastName.charAt(0).toUpperCase() + employee.emplLastName.slice(1).trim()}}</template>
		  			<template v-if="employee.emplFirstName !== null && employee.emplFirstName.length !== 0">&nbsp;{{employee.emplFirstName.trim().charAt(0).toUpperCase()}}.</template>
		  			<template v-if="employee.emplMiddleName !== null && employee.emplMiddleName.length !== 0">&nbsp;{{employee.emplMiddleName.trim().charAt(0).toUpperCase()}}.</template>
					</td>
					<td>
	  				<span v-if="employee.functions.length == 0"> - </span>
	  				<HiddenText :text="functionString(employee.functions)" :limited="100"/>
	  			</td>
	  			<td>
	  				<span class="number">{{ employee.totalTasks }}</span>
	  			</td>
	  			<td>
	  				<span class="number number--blue">{{employee.donePercent}}%</span>
	  			</td>
	  			<td>
	  				<span class="number number--dark-red">{{employee.expiredPercent}}%</span>
	  			</td>
  				<td>
	  				<template v-for="(manager, index) in employee.managers" :key="index">
	  					<template v-if="manager.lastName !== null && manager.lastName.length !== 0">
	  						{{manager.lastName.charAt(0).toUpperCase() + manager.lastName.slice(1).trim()}} 
	  					</template>
		  				<template v-if="manager.firstName !== null && manager.firstName.length !== 0">&nbsp;{{manager.firstName.trim().charAt(0).toUpperCase()}}.</template>
		  				<template v-if="manager.middleName !== null && manager.middleName.length !== 0">&nbsp;{{manager.middleName.trim().charAt(0).toUpperCase()}}.</template>{{ index != employee.managers.length - 1 ? ', ' : '' }}
	  					<br />
	  				</template>
	  			</td>

				</tr>
			</tbody>
		</table>

		<div class="empty-data" v-else>
		  	Нет данных
		</div>

	</div>
</template>

<script setup>
  import HiddenText from '@/components/ux/HiddenText.vue';
</script>

<script>

  	export default {

	    name: 'EmployersStat',

	    props: ['organization_id', 'begin', 'end'],

        expose: ['loadData'],
	    
	    data: () => ({
	    	isLoad: true,
	    	employees: [],
	    }),

	    created () {
	      	this.loadData();
	    },

	    computed: {
	    	functionString () {
	    		return (functions) => {
	    			let str = '';
	    			functions.forEach((func, idx) => {
	    				str += func;
	    				if(idx != functions.length - 1) {
	    					str += ', ';
	    				}
	    			});
	    			return str;
	    		}
	    	}
	    },

	    methods: {

	    	buildfilter () {
		        const param = new URLSearchParams({});
		        param.set("company_id", this.organization_id);
		        if(this.begin !== null) {
		          param.set("begin", this.begin + 'T00:00:00.000000+00:00');
		        }
		        if(this.end !== null) {
		          param.set("end", this.end + 'T23:59:59.999999+00:00');
		        }
		        return param;
		    },
		    
	    	async loadData () {
	    		this.isLoad = true;
	    		const param = this.buildfilter();
		        const {data} = await this.axios.get(this.$store.state.api_endpoint + '/dashboard/report-employee-director?' + param);
		        this.employees = data.employees;
	    		this.isLoad = false;
	    	}
	    },

	}

</script>

<style scoped>
	.number {
		font-weight: 600;
	}
	.number--orange {
		color: var(--cozh-orange);
	}
	.number--blue {
		color: var(--cozh-blue);
	}
	.number--red {
		color: var(--cozh-red);
	}
	.number--dark-red {
		color: var(--cozh-dark-red);
	}
</style>