<template>
	<div class="load-container" :class="{'load' : isLoad}">

		<div class="panels" v-if="dataset.length > 0">
			<div class="block" v-for="(data, index) in dataset" :key="index">
				<div class="block__body">
					<div class="block__left">
						<LineChart :chartData="data" :options="options" cssClasses="chart"/>
					</div>
					<div class="block__right">
						<div class="c-legend">
		                    <div class="c-legend__item" v-for="(item, idx) in data.datasets" :key="idx">
		                        <div class="c-legend__badge" :style="{backgroundColor: item.pointBackgroundColor}"></div> 
		                        {{ item.label }}
		                    </div>
		                </div>
					</div>
				</div>
			</div>
		</div>

		<div class="empty-data" v-else>
		  	Нет данных
		</div>

	</div>
</template>


<script setup>
    import { LineChart } from 'vue-chart-3';
    import { Chart, registerables} from 'chart.js';
	Chart.register(...registerables);
</script>


<script>
  	export default {
	    name: 'GraphEmployersHouse',
	    props: ['organization_id'],
        components: { LineChart },
	    data: () => ({
	    	
	    	isLoad: true,
	    	dataset: [],

	    	options: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    tooltip: {
                        enabled: true,
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                    }
                },
                interaction: {
                  mode: 'nearest',
                  axis: 'x',
                  intersect: false
                },
                scales: {
                    x: {
                        // offset: true,
                        ticks: {
                            font: {
                                size: 0,
                                weight: 700,
                                family: 'Inter',
                            },
                            color: '#0b2836',
                        }
                    },
                    y: {
                        // offset: true,
                        ticks: {
                            font: {
                                size: 10,
                                weight: 700,
                                family: 'Inter',
                            },
                            color: '#0b2836',
                        }
                    }
                }
            },

	    }),


	    created () {
	      	Promise.all([this.loadData()])
	      		.then(() => {
	      		})
	      		.catch((e)=> {
	        		console.log(e);
	      		})
	      		.finally(() => {
	      			this.isLoad = false;
		  		});
	    },

	    methods: {
	    	getColorByIndex (index) {
	    		switch (index) {
	    			case 0:
	    				return '#008dd2';
	    			case 1:
	    				return '#e03712';
	    			case 2:
	    				return '#ff9100';
	    			case 3:
	    				return '#7ecd77';
	    			case 4:
	    				return '#750000';
	    		}
	    	},

	    	async loadData () {
		        
		        // Подгружаем контент
		        const { data } = await this.axios.get(this.$store.state.api_endpoint + '/dashboard/diagram-house-director?company_id=' + this.organization_id);
		        
		        this.dataset = [];
		        // Цикл по домам с шагом 5
				for (let i = 0; i < data.houses.length; i += 5) {
				    
				    // Массив из 5 домов
				    const subArray = data.houses.slice(i, i + 5);

				    const currDatasets = {
				    	labels: data.dates,
				    	datasets: [],
				    }

				    subArray.forEach((item, index) => {
				    	
				    	const color = this.getColorByIndex(index);

				    	currDatasets.datasets.push({
		                    label: item.house,
		                    data: item.persents,
		                    fill: false,
		                    borderColor: color + '80',
		                    pointStyle: 'circle',
		                    pointRadius: 2,
		                    pointHoverRadius: 4,
		                    pointBackgroundColor: color,
		                    borderWidth: 1.5,
		                    tension: 0.4,
		                    isVisible: true,
		                    disabledLabel: false,
		                });

				    });
				    this.dataset.push(currDatasets);
				}
	    	}
	    }
	}
</script>

<style scoped>

	.c-legend {
		display: flex;
	    flex-direction: column;
	    align-items: flex-start;
	    justify-content: flex-start;
	    width: 130px;
	}
	.c-legend__item {
		cursor: unset;
	}
	.panels {
		display: flex;
    	flex-wrap: wrap;
    	gap: 10px;
	}

	.block {
	    width: calc(50% - 5px);
/*	    border: 1px solid var(--cozh-light-grey);*/
	}
	.block__body {
	    display: flex;
	    flex-direction: row;
	    align-items: flex-start;
	    justify-content: flex-start;
	}
	.block__left {
		width: 100%;
	    padding: 20px 0px 20px 20px;
	    border: none;
	}
	.block__right {
	    padding: 20px;
    	width: fit-content;
	} 
	.chart {
		height: 300px;
	}

</style>